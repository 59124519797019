import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import Sidebar from "./Sidebar";
import Settings from "./Settings";
import NewStory from "./NewStory";
import Admin from "./Admin";
import Organizations from "./Organizations";
import MyStories from "./MyStories";

const Dashboard = ({ user }) => {
  return (
    <div className='dashboard-wrapper'>
      <Sidebar user={user} />
      <div className='dashboard-container'>
        <Routes>
          <Route
            path='/'
            element={
              <div>
                <h3>Welcome to Laio</h3>
              </div>
            }
          />
          <Route path='new-story' element={<NewStory user={user} />} />
          <Route path='my-stories' element={<MyStories />} />
          <Route path='settings' element={<Settings user={user} />} />{" "}
          <Route path='admin' element={<Admin user={user} />} />{" "}
          {/* Matches only /admin */}
          <Route
            path='admin/organization/:orgId'
            element={<Organizations user={user} />}
          />{" "}
          {/* Matches /admin/organization/:id */}
        </Routes>
      </div>
    </div>
  );
};

export default Dashboard;
