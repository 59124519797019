import React, { useState, useEffect } from "react";
import { authClearMessages } from "../actions/auth";
import { connect, useSelector, useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { changePassword } from "../actions/user";

const ChangePassword = ({ changePassword, user_id }) => {
  const [formData, setFormData] = useState({
    old_password: "",
    new_password: "",
    confirm_new_password: "",
  });
  const [passwordChanged, setpasswordChanged] = useState(false);

  const { old_password, new_password, confirm_new_password } = formData;
  const success = useSelector((state) => state.auth.success);
  const error = useSelector((state) => state.auth.error);
  const dispatch = useDispatch();

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();

    if (new_password === confirm_new_password) {
      changePassword(user_id, old_password, new_password);
      setpasswordChanged(true);
    } else {
      toast.error("Passwords don't match", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  useEffect(() => {
    if (success) {
      toast.success(success, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      dispatch(authClearMessages());
    }
    if (error) {
      toast.error(error, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      dispatch(authClearMessages());
    }
  }, [success, error]);

  return (
    <form onSubmit={(e) => onSubmit(e)}>
      <ToastContainer />
      <div className='form-group__wrapper'>
        <label className='form-label'>Old Password:</label>
        <input
          className='form-control'
          type='password'
          placeholder='Old Password'
          name='old_password'
          value={old_password}
          onChange={(e) => onChange(e)}
          required
        />
      </div>
      <div className='form-group__wrapper mt-4'>
        <label className='form-label'>Password:</label>
        <input
          className='form-control'
          type='password'
          placeholder='New Password'
          name='new_password'
          value={new_password}
          minLength='6'
          onChange={(e) => onChange(e)}
          required
        />
      </div>
      <div className='form-group__wrapper mt-4'>
        <label className='form-label'>Confirm Password:</label>
        <input
          className='form-control'
          type='password'
          placeholder='Confirm New Password'
          name='confirm_new_password'
          value={confirm_new_password}
          onChange={(e) => onChange(e)}
          required
        />
      </div>
      <div className='form-group__wrapper'>
        <button className='btn btn-primary mt-4' type='submit'>
          Change Password
        </button>
      </div>
    </form>
  );
};

const mapStateToProps = (state) => ({
  user_id: state.auth.user ? state.auth.user.id : null,
});

export default connect(mapStateToProps, { changePassword })(ChangePassword);
