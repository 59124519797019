// Auth Dispatch States
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
// export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAIL = "LOGOUT_FAIL";
export const AUTHENTICATED_SUCCESS = "AUTHENTICATED_SUCCESS";
export const AUTHENTICATED_FAIL = "AUTHENTICATED_FAIL";
// export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
// export const DELETE_USER_FAIL = "DELETE_USER_FAIL";
// export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
// export const CHANGE_PASSWORD_FAIL = "CHANGE_PASSWORD_FAIL";
export const GET_ORGANIZATION_USERS_SUCCESS = "GET_ORGANIZATION_USERS_SUCCESS";
export const ADD_USER_TO_ORGANIZATION_SUCCESS =
  "ADD_USER_TO_ORGANIZATION_SUCCESS";
export const GET_ORGANIZATION_USERS_FAIL = "GET_ORGANIZATION_USERS_FAIL";
export const ADD_USER_TO_ORGANIZATION_FAIL = "ADD_USER_TO_ORGANIZATION_FAIL";
export const ADD_USER_TO_ORGANIZATION_SUPER_SUCCESS =
  "ADD_USER_TO_ORGANIZATION_SUPER_SUCCESS";
export const ADD_USER_TO_ORGANIZATION_SUPER_FAIL =
  "ADD_USER_TO_ORGANIZATION_SUPER_FAIL";
export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
// export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
// export const RESET_PASSWORD_FAIL = "RESET_PASSWORD_FAIL";

// Profile Dispatch States
export const LOAD_USER_PROFILE_SUCCESS = "LOAD_USER_PROFILE_SUCCESS";
export const LOAD_USER_PROFILE_FAIL = "LOAD_USER_PROFILE_FAIL";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_FAIL = "GET_USER_FAIL";
export const UPDATE_USER_PROFILE_SUCCESS = "UPDATE_USER_PROFILE_SUCCESS";
export const UPDATE_USER_PROFILE_FAIL = "UPDATE_USER_PROFILE_FAIL";

// Organization Dispatch States
export const GET_ORGANIZATION_SUCCESS = "GET_ORGANIZATION_SUCCESS";
export const GET_ORGANIZATION_FAIL = "GET_ORGANIZATION_FAIL";
// export const CREATE_ORGANIZATION_FAIL = "CREATE_ORGANIZATION_FAIL";
// export const CREATE_ORGANIZATION_SUCCESS = "CREATE_ORGANIZATION_SUCCESS";
// export const DELETE_ORGANIZATION_FAIL = "DELETE_ORGANIZATION_FAIL";
// export const DELETE_ORGANIZATION_SUCCESS = "DELETE_ORGANIZATION_SUCCESS";
export const DELETE_ORGANIZATION_USER_SUCCESS =
  "DELETE_ORGANIZATION_USER_SUCCESS";
export const DELETE_ORGANIZATION_USER_FAIL = "DELETE_ORGANIZATION_USER_FAIL";
export const CLEAR_MESSAGES = "CLEAR_MESSAGES";
export const CHECK_ADMIN_STATUS = "CHECK_ADMIN_STATUS";
export const CHANGE_USER_ROLE_SUCCESS = "CHANGE_USER_ROLE_SUCCESS";
export const CHANGE_USER_ROLE_FAIL = "CHANGE_USER_ROLE_FAIL";

// Article creation process dispatch states
export const SET_PROMPT = "SET_PROMPT";
export const SUBMIT_INPUT_SUCCESS = "SUBMIT_INPUT_SUCCESS";
export const SUBMIT_INPUT_FAIL = "SUBMIT_INPUT_FAIL";
// export const EXPAND_INPUT_SUCCESS = "EXPAND_INPUT_SUCCESS";
// export const EXPAND_INPUT_FAIL = "EXPAND_INPUT_FAIL";
export const GET_INPUT_WITHOUT_URLS_SUCCESS = "GET_INPUT_WITHOUT_URLS_SUCCESS";
export const GET_INPUT_WITHOUT_URLS_FAIL = "GET_INPUT_WITHOUT_URLS_FAIL";
export const CREATE_SEARCH_TERMS_SUCCESS = "CREATE_SEARCH_TERMS_SUCCESS";
export const CREATE_SEARCH_TERMS_FAIL = "CREATE_SEARCH_TERMS_FAIL";
export const MOVE_NEXT_STEP = "MOVE_NEXT_STEP";
export const MOVE_PREVIOUS_STEP = "MOVE_PREVIOUS_STEP";
export const UPDATE_SEARCH_TERMS = "UPDATE_SEARCH_TERMS";
export const SET_CURRENT_STEP = "SET_CURRENT_STEP";
export const SET_SEARCH_TERMS = "SET_SEARCH_TERMS";
export const POST_SEARCH_TERMS_SUCCESS = "POST_SEARCH_TERMS_SUCCESS";
export const POST_SEARCH_TERMS_FAIL = "POST_SEARCH_TERMS_FAIL";
// export const GET_SEARCH_RESULTS_SUCCESS = "GET_SEARCH_RESULTS_SUCCESS";
// export const GET_SEARCH_RESULTS_FAIL = "GET_SEARCH_RESULTS_FAIL";
export const GET_COARSE_FILTER_SUCCESS = "GET_COARSE_FILTER_SUCCESS";
export const GET_COARSE_FILTER_FAIL = "GET_COARSE_FILTER_FAIL";
export const TOGGLE_SOURCE_SELECTION = "TOGGLE_SOURCE_SELECTION";
export const SAVE_SELECTED_SOURCES = "SAVE_SELECTED_SOURCES";
export const POST_COARSE_FILTER_SUCCESS = "POST_COARSE_FILTER_SUCCESS";
export const POST_COARSE_FILTER_FAIL = "POST_COARSE_FILTER_FAIL";
// export const GET_FINE_SEARCH_FILTER_SUCCESS = "GET_FINE_SEARCH_FILTER_SUCCESS";
// export const GET_FINE_SEARCH_FILTER_FAIL = "GET_FINE_SEARCH_FILTER_FAIL";
export const GET_RESEARCH_REVIEW_SUCCESS = "GET_RESEARCH_REVIEW_SUCCESS";
export const GET_RESEARCH_REVIEW_FAIL = "GET_RESEARCH_REVIEW_SUCCESS";
export const GET_OUTLINE_SUCCESS = "GET_OUTLINE_SUCCESS";
export const GET_OUTLINE_FAIL = "GET_OUTLINE_SUCCESS";
export const GET_TITLE_SUCCESS = "GET_TITLE_SUCCESS";
export const GET_TITLE_FAIL = "GET_TITLE_SUCCESS";
export const POST_OUTLINE_SUCCESS = "POST_OUTLINE_SUCCESS";
export const POST_OUTLINE_FAIL = "POST_OUTLINE_SUCCESS";
export const POST_TITLE_SUCCESS = "POST_TITLE_SUCCESS";
export const POST_TITLE_FAIL = "POST_TITLE_SUCCESS";
export const GET_ARTICLE_BODY_SUCCESS = "GET_ARTICLE_BODY_SUCCESS";
export const GET_ARTICLE_BODY_FAIL = "GET_ARTICLE_BODY_SUCCESS";
export const GET_ARTICLE_HTML_SUCCESS = "GET_ARTICLE_HTML_SUCCESS";
export const GET_ARTICLE_HTML_FAIL = "GET_ARTICLE_HTML_FAIL";
export const GET_ARTICLES_SUCCESS = "GET_ARTICLES_SUCCESS";
export const GET_ARTICLES_FAIL = "GET_ARTICLES_FAIL";

// Article creation process dispatch states NEW
export const EXPAND_INPUT_SUCCESS = "EXPAND_INPUT_SUCCESS";
export const EXPAND_INPUT_FAIL = "EXPAND_INPUT_FAIL";
export const SAVE_SUMMARIES = "SAVE_SUMMARIES";
export const SAVE_MORE_INFO_ANSWERS = "SAVE_MORE_INFO_ANSWERS";
export const UPDATE_INTRO = "UPDATE_INTRO";
export const UPDATE_TITLE = "UPDATE_TITLE";
export const GENERATE_SUMMARY_SUCCESS = "GENERATE_SUMMARY_SUCCESS";
export const GENERATE_SUMMARY_FAIL = "GENERATE_SUMMARY_FAIL";
export const GET_SEARCH_WORDS_SUCCESS = "GET_SEARCH_WORDS_SUCCESS";
export const GET_SEARCH_WORDS_FAIL = "GET_SEARCH_WORDS_FAIL";
export const GET_SEARCH_RESULTS_SUCCESS = "GET_SEARCH_RESULTS_SUCCESS";
export const GET_SEARCH_RESULTS_FAIL = "GET_SEARCH_RESULTS_FAIL";
export const GET_MORE_INFO_FAIL = "GET_MORE_INFO_FAIL";
export const GET_MORE_INFO_SUCCESS = "GET_MORE_INFO_SUCCESS";
export const WRITE_TITLE_INTRO_FAIL = "WRITE_TITLE_INTRO_FAIL";
export const WRITE_TITLE_INTRO_SUCCESS = "WRITE_TITLE_INTRO_SUCCESS";
export const WRITE_ARTICLE_FAIL = "WRITE_ARTICLE_FAIL";
export const WRITE_ARTICLE_SUCCESS = "WRITE_ARTICLE_SUCCESS";

//user NEW
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const LOGOUT = "LOGOUT";
export const LOAD_USER_SUCCESS = "LOAD_USER_SUCCESS";
export const LOAD_USER_FAIL = "LOAD_USER_FAIL";
export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS";
export const CREATE_USER_FAIL = "CREATE_USER_FAIL";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAIL = "RESET_PASSWORD_FAIL";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAIL = "DELETE_USER_FAIL";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAIL = "CHANGE_PASSWORD_FAIL";
export const USER_ROLE_UPDATED = "USER_ROLE_UPDATED";
export const GET_PROFILES_SUCCESS = "GET_PROFILES_SUCCESS";
export const GET_PROFILES_FAILURE = "GET_PROFILES_FAILURE";

//organizations NEW
export const CREATE_ORGANIZATION_SUCCESS = "CREATE_ORGANIZATION_SUCCESS";
export const CREATE_ORGANIZATION_FAIL = "CREATE_ORGANIZATION_FAIL";
export const FETCH_ORGANIZATIONS_FAIL = "FETCH_ORGANIZATIONS_FAIL";
export const FETCH_ORGANIZATIONS_SUCCESS = "FETCH_ORGANIZATIONS_SUCCESS";
export const FETCH_ORGANIZATION_FAIL = "FETCH_ORGANIZATION_FAIL";
export const FETCH_ORGANIZATION_SUCCESS = "FETCH_ORGANIZATION_SUCCESS";
export const DELETE_ORGANIZATION_SUCCESS = "DELETE_ORGANIZATION_SUCCESS";
export const DELETE_ORGANIZATION_FAIL = "DELETE_ORGANIZATION_FAIL";
export const FETCH_PROFILES_SUCCESS = "FETCH_PROFILES_SUCCESS";
export const FETCH_PROFILES_FAIL = "FETCH_PROFILES_FAIL";
export const FETCH_SAVE_PROFILES_SUCCESS = "FETCH_SAVE_PROFILES_SUCCESS";
export const FETCH_SAVE_PROFILES_FAIL = "FETCH_SAVE_PROFILES_FAIL";
export const UPDATE_ORGANIZATION_PROFILES_SUCCESS =
  "UPDATE_ORGANIZATION_PROFILES_SUCCESS";
export const UPDATE_ORGANIZATION_PROFILES_FAIL =
  "UPDATE_ORGANIZATION_PROFILES_FAIL";
