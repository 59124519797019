import { combineReducers } from "redux";
import auth from "./auth";
import profile from "./profile";
import organizations from "./organizations";
import article from "./article";
import user from "./user";

export default combineReducers({
  auth,
  profile,
  organizations,
  article,
  user,
});
