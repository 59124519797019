import axios from "axios";
import {
  CREATE_ORGANIZATION_SUCCESS,
  CREATE_ORGANIZATION_FAIL,
  FETCH_ORGANIZATIONS_SUCCESS,
  FETCH_ORGANIZATIONS_FAIL,
  FETCH_ORGANIZATION_SUCCESS,
  FETCH_ORGANIZATION_FAIL,
  DELETE_ORGANIZATION_SUCCESS,
  DELETE_ORGANIZATION_FAIL,
  CLEAR_MESSAGES,
  FETCH_PROFILES_SUCCESS,
  FETCH_PROFILES_FAIL,
  FETCH_SAVE_PROFILES_SUCCESS,
  FETCH_SAVE_PROFILES_FAIL,
  UPDATE_ORGANIZATION_PROFILES_SUCCESS,
  UPDATE_ORGANIZATION_PROFILES_FAIL,
} from "./types";

// Action to create an organization
export const createOrganization = (organizationData) => async (dispatch) => {
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}api/organizations`,
      organizationData,
      config
    ); // Adjust the URL if needed
    dispatch({
      type: CREATE_ORGANIZATION_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: CREATE_ORGANIZATION_FAIL,
    });
  }
};

export const fetchOrganizations = () => async (dispatch) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}api/organizations`
    );
    dispatch({
      type: FETCH_ORGANIZATIONS_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    console.error("Fetch organizations error:", err);
    dispatch({
      type: FETCH_ORGANIZATIONS_FAIL,
      payload: err.response.data.message || "Failed to fetch organizations",
    });
  }
};

export const fetchOrganization = (id) => async (dispatch) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}api/organizations/${id}`
    );
    dispatch({
      type: FETCH_ORGANIZATION_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    console.error("Fetch organization error:", err);
    dispatch({
      type: FETCH_ORGANIZATION_FAIL,
      payload: err.response.data.message || "Failed to fetch organization",
    });
  }
};

export const deleteOrganization = (id) => async (dispatch) => {
  try {
    const res = await axios.delete(
      `${process.env.REACT_APP_API_URL}api/organizations/${id}`
    );

    dispatch({
      type: DELETE_ORGANIZATION_SUCCESS,
      payload: id,
    });
  } catch (err) {
    dispatch({
      type: DELETE_ORGANIZATION_FAIL,
      payload: err.response.data.message || "Failed to delete organization",
    });
  }
};

export const OrgsClearMessages = () => ({
  type: CLEAR_MESSAGES,
});

export const fetchProfiles = () => async (dispatch) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/profiles/fetchFromDatabase`
    );
    dispatch({
      type: FETCH_PROFILES_SUCCESS,
      payload: response.data,
    });
  } catch (err) {
    dispatch({
      type: FETCH_PROFILES_FAIL,
      payload: err.message,
    });
  }
};

export const fetchAndSaveProfiles = () => async (dispatch) => {
  console.log("fetchAndSaveProfiles action creator called");
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/profiles/fetch-and-save`
    );
    console.log("Response from API:", response.data);
    dispatch({
      type: FETCH_SAVE_PROFILES_SUCCESS,
      payload: response.data,
    });
  } catch (err) {
    console.error("Error fetching and saving profiles:", err);
    dispatch({
      type: FETCH_SAVE_PROFILES_FAIL,
      payload: err.message,
    });
  }
};

export const updateOrganizationProfiles =
  (organizationId, profileIds) => async (dispatch) => {
    try {
      await axios.put(
        `${process.env.REACT_APP_API_URL}/api/profiles/update-organization`,
        {
          organizationId,
          profileIds,
        }
      );
      dispatch({
        type: UPDATE_ORGANIZATION_PROFILES_SUCCESS,
      });
    } catch (err) {
      console.error("Update organization profiles error:", err);
      dispatch({
        type: UPDATE_ORGANIZATION_PROFILES_FAIL,
        payload:
          err.response.data.message || "Failed to update organization profiles",
      });
    }
  };
