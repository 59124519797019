import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  moveNextStep,
  movePreviousStep,
  updateTitle,
  writeArticle,
  updateIntro,
} from "../../actions/article";
import LoadingIndicator from "./LoadingIndicator";

const Outline = ({ user }) => {
  const dispatch = useDispatch();
  const formData = useSelector((state) => state.article.formData);
  const {
    prompt,
    title,
    intro,
    summaries,
    more_info_questions,
    more_info_answers,
  } = formData;
  const orgId = user ? user.orgId : null;

  const [loading, setLoading] = useState(false);

  const handleTitleChange = (e) => {
    dispatch(updateTitle(e.target.value));
  };

  const handleSummaryChange = (e) => {
    dispatch(updateIntro(e.target.value));
  };

  const handleNextStep = async () => {
    setLoading(true);
    try {
      await dispatch(
        writeArticle(
          prompt,
          more_info_questions,
          more_info_answers,
          title,
          intro,
          summaries,
          orgId
        )
      );
      dispatch(moveNextStep());
    } catch (error) {
      console.error("Error writing article:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className='step-content dashboard-input-wrapper step-5'>
      {loading ? (
        <div className='dotloader-wrapper'>
          <LoadingIndicator text='Writing your article' />
        </div>
      ) : (
        <>
          <div className='dashboard-input-container outline-container'>
            <h4 className='instructions'>
              You can edit the title and intro here, or leave it as is.
            </h4>
            <hr />
            <div>
              <p>Title:</p>
              <input type='text' value={title} onChange={handleTitleChange} />
            </div>
            <div className='outline-intro-container'>
              <p>Intro:</p>
              <textarea value={intro} onChange={handleSummaryChange} />
            </div>
          </div>
          <div className='step-nav'>
            <button onClick={() => dispatch(movePreviousStep())}>Back</button>
            <button type='button' onClick={handleNextStep}>
              Next Step
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default Outline;
