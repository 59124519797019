import {
  LOAD_USER_PROFILE_SUCCESS,
  LOAD_USER_PROFILE_FAIL,
  UPDATE_USER_PROFILE_SUCCESS,
  UPDATE_USER_PROFILE_FAIL,
  CLEAR_MESSAGES,
} from "../actions/types";

const initialState = {
  username: "",
  first_name: "",
  last_name: "",
  email: "",
  preferred_language: "",
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case LOAD_USER_PROFILE_SUCCESS:
    case UPDATE_USER_PROFILE_SUCCESS:
      return {
        ...state,
        username: payload.username,
        first_name: payload.profile.first_name,
        last_name: payload.profile.last_name,
        email: payload.profile.email,
        preferred_language: payload.profile.preferred_language,
      };
    case LOAD_USER_PROFILE_FAIL:
      return {
        ...state,
        username: "",
        first_name: "",
        last_name: "",
        email: "",
        preferred_language: "",
      };
    case CLEAR_MESSAGES:
      return {
        ...state,
        success: null,
        error: null,
        message: null,
      };
    case UPDATE_USER_PROFILE_FAIL:
      return {
        ...state,
      };
    default:
      return state;
  }
}
