import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getArticles } from "../actions/article";

const MyStories = () => {
  const dispatch = useDispatch();
  const articles = useSelector((state) => state.article.articles);

  useEffect(() => {
    // dispatch(getArticles());
  }, [dispatch]);

  return (
    <div className='dashboard-input-wrapper my-stories-wrapper'>
      <h3 className=''>Coming Soon</h3>
      {Array.isArray(articles) &&
        articles.map(
          (article) =>
            article.article_outline && (
              <ArticleCard key={article.id} article={article} />
            )
        )}
    </div>
  );
};

const ArticleCard = ({ article }) => {
  // const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div className='card'>
      <div className='card-body'>
        <h5 className='card-title'>{article.article_title}</h5>
        <p className='card-article-outline'>{article.article_outline}</p>
        {/* <button
          className='btn btn-primary'
          onClick={() => setIsExpanded(!isExpanded)}
        >
          {isExpanded ? "Read Less" : "Read More"}
        </button> */}
      </div>
    </div>
  );
};

export default MyStories;
