import React, { Fragment } from "react";
import { Link, NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { logout } from "../actions/auth";

const Navbar = ({ isAuthenticated, logout }) => {
  const authLinks = (
    <Fragment>
      <NavLink className='nav-link' aria-current='page' to='/dashboard'>
        Dashboard
      </NavLink>
      <NavLink
        className='nav-link'
        aria-current='page'
        onClick={logout}
        href='#!'
      >
        Logout
      </NavLink>
    </Fragment>
  );

  const guestLinks = (
    <Fragment>
      <NavLink className='nav-link' aria-current='page' to='/login'>
        Login
      </NavLink>
      {/* <NavLink className='nav-link' aria-current='page' to='/register'>
        Register
      </NavLink> */}
    </Fragment>
  );
  return (
    <nav className='navbar navbar-expand-lg bg-body-tertiary'>
      <div className='container-fluid'>
        <Link className='navbar-brand' exact to='/'>
          Laio
        </Link>
        <button
          className='navbar-toggler'
          type='button'
          data-bs-toggle='collapse'
          data-bs-target='#navbarNavAltMarkup'
          aria-controls='navbarNavAltMarkup'
          aria-expanded='false'
          aria-label='Toggle navigation'
        >
          <span className='navbar-toggler-icon'></span>
        </button>
        <div className='collapse navbar-collapse' id='navbarNavAltMarkup'>
          <div className='navbar-nav'>
            <NavLink className='nav-link' aria-current='page' exact to='/'>
              Home
            </NavLink>
            {isAuthenticated ? authLinks : guestLinks}
          </div>
        </div>
      </div>
    </nav>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { logout })(Navbar);
