import axios from "axios";
import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  LOAD_USER_SUCCESS,
  LOAD_USER_FAIL,
  RESET_PASSWORD_FAIL,
  RESET_PASSWORD_SUCCESS,
  CLEAR_MESSAGES,
} from "./types";

// Action to check authentication state
export const checkAuth = () => async (dispatch) => {
  const token = localStorage.getItem("token"); // Load token from local storage
  if (token) {
    // Token exists, user is authenticated
    dispatch({ type: LOGIN_SUCCESS });
  } else {
    // No token, user is not authenticated
    dispatch({ type: LOGOUT });
  }
};

// Action to log in
export const login = (email, password) => async (dispatch) => {
  try {
    const res = await axios.post(`${process.env.REACT_APP_API_URL}api/login`, {
      email, // Send email instead of username
      password,
    });
    localStorage.setItem("token", res.data.token); // Store token in localStorage
    const tokenExpiration = Date.now() + 30 * 24 * 60 * 60 * 1000; // 30 days expiration
    localStorage.setItem("tokenExpiration", tokenExpiration); // Store token expiration in localStorage
    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data.token,
    });
  } catch (err) {
    console.error("Login error:", err); // Log login error
    dispatch({
      type: LOGIN_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.data.message,
    });
  }
};

// Action to log out
export const logout = () => async (dispatch) => {
  localStorage.removeItem("token"); // Remove token from local storage
  dispatch({ type: LOGOUT });
};

// Action to load user data and set token expiration
export const loadUser = () => async (dispatch) => {
  try {
    const token = localStorage.getItem("token");
    const tokenExpiration = localStorage.getItem("tokenExpiration");
    if (token && tokenExpiration) {
      const currentTime = Date.now();
      if (currentTime < tokenExpiration) {
        // Token is still valid
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
        const res = await axios.get(
          `${process.env.REACT_APP_API_URL}api/loaduser`,
          config
        );
        dispatch({
          type: LOAD_USER_SUCCESS,
          payload: res.data,
        });
      } else {
        // Token has expired, dispatch logout action
        dispatch({ type: LOGOUT });
      }
    } else {
      // No token or token expiration found
      dispatch({ type: LOAD_USER_FAIL });
    }
  } catch (err) {
    dispatch({
      type: LOAD_USER_FAIL,
    });
  }
};

export const resetPassword = (token, newPassword) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const body = {
      newPassword,
    };

    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}api/users/set-password/${token}`, // Corrected URL format
      body,
      config
    );

    if (res.data.message === "Password set successfully") {
      dispatch({
        type: RESET_PASSWORD_SUCCESS,
        payload: res.data.message,
      });
    } else {
      dispatch({
        type: RESET_PASSWORD_FAIL,
        payload: "Failed to reset password",
      });
    }
  } catch (error) {
    dispatch({
      type: RESET_PASSWORD_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const authClearMessages = () => ({
  type: CLEAR_MESSAGES,
});
