import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  OrgsClearMessages,
  fetchOrganizations,
  createOrganization,
  deleteOrganization,
  fetchAndSaveProfiles,
  fetchProfiles,
  updateOrganizationProfiles,
} from "../actions/organizations";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { createUser } from "../actions/user";

const Admin = ({
  fetchOrganizations,
  createOrganization,
  organizations,
  deleteOrganization,
  createUser,
  user,
  profiles,
}) => {
  const [orgName, setName] = useState("");
  const [orgId, setOrgId] = useState("");
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const navigate = useNavigate();
  const success = useSelector((state) => state.organizations.success);
  const error = useSelector((state) => state.organizations.error);
  const dispatch = useDispatch();
  const [selectedOrganization, setSelectedOrganization] = useState(null);
  const [selectedProfiles, setSelectedProfiles] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const successMessage = useSelector((state) => state.user.successMessage);
  const errorMessage = useSelector((state) => state.user.errorMessage);

  // // Fetch profiles when component mounts
  // useEffect(() => {
  // }, [fetchProfiles]);

  const handleCreateOrganization = (e) => {
    e.preventDefault();
    const slug = orgName.toLowerCase().split(" ").join("-");
    const orgData = {
      name: orgName,
      slug: slug,
    };
    createOrganization(orgData);
  };

  useEffect(() => {
    fetchProfiles();

    fetchOrganizations();
    if (success) {
      toast.success(success, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      dispatch(OrgsClearMessages());
    }
    if (error) {
      toast.error(error, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      dispatch(OrgsClearMessages());
    }
    if (successMessage) {
      toast.success(successMessage, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      dispatch(OrgsClearMessages());
    }
    if (errorMessage) {
      toast.error(errorMessage, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      dispatch(OrgsClearMessages());
    }
  }, [
    fetchOrganizations,
    fetchProfiles,
    success,
    error,
    successMessage,
    errorMessage,
    dispatch,
  ]);

  const handleDeleteOrganization = (id) => {
    if (window.confirm("Are you sure you want to delete this organization?")) {
      deleteOrganization(id).then(() => fetchOrganizations());
    }
  };

  const handleInviteUserSuper = (e) => {
    e.preventDefault();
    createUser(email, orgId);
    setEmail("");
    setOrgId("");
  };

  const handleProfileSelection = () => {
    if (selectedOrganization && selectedProfiles[selectedOrganization]) {
      updateOrganizationProfiles(
        selectedOrganization,
        selectedProfiles[selectedOrganization]
      );
    }
  };
  const handleFetchAndSaveProfiles = () => {
    console.log("button clicked");
    fetchAndSaveProfiles();
  };

  return (
    <>
      {" "}
      <div className='dashboard-input-wrapper admin-wrapper'>
        <ToastContainer />
        <div className='dashboard-input-container'>
          <h1>Organizations</h1>
          <form onSubmit={handleCreateOrganization}>
            <div className='input-group mb-3'>
              <input
                type='text'
                className='form-control'
                placeholder='Name'
                value={orgName}
                onChange={(e) => setName(e.target.value)}
              />
              <button className='btn btn-primary' type='submit'>
                Add
              </button>
            </div>
          </form>
          <table className='table table-hover'>
            <thead>
              <tr>
                <th className='organization-name' scope='col'>
                  Name
                </th>
              </tr>
            </thead>
            <tbody>
              {Array.isArray(organizations) &&
                organizations.map((org) => (
                  <tr
                    key={org.id}
                    onClick={() =>
                      navigate(`/dashboard/admin/organization/${org.id}`)
                    }
                  >
                    <td className='organization-name'>{org.name}</td>
                    <td>
                      <button
                        className='delete-button'
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDeleteOrganization(org.id);
                        }}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <div className='dashboard-input-container'>
          <h2>Invite user to organization</h2>
          <form onSubmit={handleInviteUserSuper}>
            <div className='form-group__wrapper mt-4'>
              <label>Email address</label>
              <input
                type='email'
                className='form-control'
                placeholder='Enter email'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className='form-group__wrapper mt-4'>
              <label>Organization</label>
              <select
                className='form-control'
                value={orgId}
                onChange={(e) => setOrgId(e.target.value)}
                required
              >
                <option value=''>Select Organization</option>
                {Array.isArray(organizations) &&
                  organizations.map((org) => (
                    <option key={org.id} value={org.id}>
                      {org.name}
                    </option>
                  ))}
              </select>
            </div>
            <div className='form-group__wrapper'>
              <button className='btn btn-primary mt-4' type='submit'>
                Invite
              </button>
            </div>
          </form>
        </div>
        <div className='dashboard-input-container'>
          {/* Display dropdown to select organization */}
          <div className='dropdown'>
            <button
              className='dropdown-toggle'
              type='button'
              id='dropdownMenuButton'
              onClick={() => setShowDropdown(!showDropdown)} // Toggle dropdown visibility
              aria-haspopup='true'
              aria-expanded={showDropdown ? "true" : "false"}
            >
              {selectedOrganization
                ? selectedOrganization.name
                : "Select Organization"}
            </button>
            {showDropdown && (
              <div
                className='dropdown-menu'
                aria-labelledby='dropdownMenuButton'
              >
                {organizations.map((org) => (
                  <button
                    key={org.id}
                    className='dropdown-item'
                    onClick={() => {
                      setSelectedOrganization(org);
                      setShowDropdown(false); // Close the dropdown when an item is clicked
                    }}
                  >
                    {org.name}
                  </button>
                ))}
              </div>
            )}
          </div>

          {/* Button to save profiles */}
          <button
            className='btn btn-primary mt-4'
            onClick={handleProfileSelection}
            disabled={!selectedOrganization}
          >
            Save Profiles
          </button>
        </div>
      </div>
      <div className='dashboard-input-container'>
        <button
          className='btn btn-primary mt-4'
          onClick={handleFetchAndSaveProfiles}
        >
          Fetch and Save Profiles
        </button>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  organizations: state.organizations.organizations,
  user: state.auth.user ? state.auth.user : null,
  profiles: state.organizations.profiles,
});

export default connect(mapStateToProps, {
  fetchOrganizations,
  createOrganization,
  deleteOrganization,
  createUser,
  fetchAndSaveProfiles,
  fetchProfiles,
  updateOrganizationProfiles,
})(Admin);
