import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { OrgsClearMessages, fetchOrganization } from "../actions/organizations";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { ToastContainer, toast } from "react-toastify";
import { createUser, deleteUser, updateUserRole } from "../actions/user";

const Organizations = ({
  fetchOrganization,
  createUser,
  organizations,
  isAdmin,
  isSuperuser,
  user,
}) => {
  const { orgId } = useParams();
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const dispatch = useDispatch();
  const success = useSelector((state) => state.organizations.success);
  const error = useSelector((state) => state.organizations.error);

  useEffect(() => {
    fetchOrganization(orgId);
    if (success) {
      toast.success(success, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      dispatch(OrgsClearMessages());
    }
    if (error) {
      toast.error(error, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      dispatch(OrgsClearMessages());
    }
  }, [fetchOrganization, orgId, success, error, dispatch]);

  const handleDeleteUser = (userId) => {
    if (
      window.confirm(
        "Are you sure you want to delete user from your organization?"
      )
    ) {
      dispatch(deleteUser(userId)).then(() => fetchOrganization(orgId));
    }
  };

  const handleUserRoleChange = (userId, isAdmin) => {
    dispatch(updateUserRole(userId, isAdmin)).then(() => {
      fetchOrganization(orgId);
    });
  };

  const handleInviteUser = (e) => {
    e.preventDefault();
    createUser(email, orgId).then(() => fetchOrganization(orgId));
    setEmail("");
    setUsername("");
  };

  return (
    <div className='dashboard-input-wrapper'>
      <ToastContainer />
      <div className='dashboard-input-container'>
        {organizations && <h1>{organizations.name}</h1>}
      </div>
      <div className='dashboard-input-container'>
        <h2>Users</h2>
        <table className='table table-striped table-hover'>
          <thead>
            <tr>
              <th className='author'>Author</th>
              {(isAdmin || isSuperuser) && <th>Role</th>}
              {(isAdmin || isSuperuser) && <th>Delete</th>}
            </tr>
          </thead>
          <tbody>
            {organizations &&
              Array.isArray(organizations.users) &&
              organizations.users.map((user) => (
                <tr key={user.id}>
                  <td className='author'>
                    <div>
                      <h6 className='username'>{user.username}</h6>
                      <p className='email'>{user.email}</p>
                    </div>
                  </td>
                  {(isAdmin || isSuperuser) && (
                    <td>
                      <select
                        value={user.is_admin ? "admin" : "user"}
                        onChange={(e) =>
                          handleUserRoleChange(
                            user.id,
                            e.target.value === "admin"
                          )
                        }
                      >
                        <option value='admin'>Admin</option>
                        <option value='user'>User</option>
                      </select>
                    </td>
                  )}
                  {(isAdmin || isSuperuser) && (
                    <td>
                      <button
                        className='delete-button'
                        onClick={() => handleDeleteUser(user.id)}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </button>
                    </td>
                  )}
                </tr>
              ))}
          </tbody>
        </table>
      </div>

      <div className='dashboard-input-container'>
        <h2>Invite User</h2>
        <form onSubmit={handleInviteUser}>
          <div className='form-group__wrapper mt-4'>
            <label>Email address</label>
            <input
              type='email'
              className='form-control'
              placeholder='Enter email'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className='form-group__wrapper'>
            <button className='btn btn-primary mt-4' type='submit'>
              Invite
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  organizations: state.organizations.organizations,
  isSuperuser: state.auth.user ? state.auth.user.is_superuser : null,
  isAdmin: state.auth.user ? state.auth.user.is_admin : null,
});

export default connect(mapStateToProps, {
  fetchOrganization,
  createUser,
  deleteUser,
})(Organizations);
