import React from "react";
import { PulseLoader } from "react-spinners";

const LoadingIndicator = ({ text }) => {
  return (
    <div className='loading-indicator'>
      <h2>{text}</h2>
      <PulseLoader color='#183620' loading={true} size={12} />
    </div>
  );
};

export default LoadingIndicator;
