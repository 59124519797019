import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { useDispatch, useSelector } from "react-redux";

import { login, loadUser, authClearMessages } from "../actions/auth";
import { ToastContainer, toast } from "react-toastify";

const Login = ({ login, loadUser, isAuthenticated }) => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const { email, password } = formData;
  const navigate = useNavigate();
  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });
  const dispatch = useDispatch();
  const message = useSelector((state) => state.auth.message);
  const error = useSelector((state) => state.auth.error);

  const onSubmit = (e) => {
    e.preventDefault();
    login(email, password);
  };

  useEffect(() => {
    if (message) {
      toast.success(message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      dispatch(authClearMessages());
    }
    if (error) {
      toast.error(error, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      dispatch(authClearMessages());
    }
  }, [message, error, dispatch]);

  useEffect(() => {
    if (isAuthenticated) {
      // Dispatch loadUser action after successful login
      loadUser();
      navigate("/dashboard");
    }
  }, [isAuthenticated, navigate, loadUser]); // Add loadUser dependency to useEffect

  return (
    <div className='container auth-page-container'>
      <ToastContainer />

      <div className='wrapper-container'>
        <h1>Sign In</h1>
        <hr />
        <form onSubmit={onSubmit}>
          <div className='form-group'>
            <label className='form-label'>Email: </label>
            <input
              className='form-control'
              type='text'
              placeholder='Email*'
              name='email'
              onChange={onChange}
              value={email}
              required
            />
          </div>
          <div className='form-group'>
            <label className='form-label mt-3'>Password: </label>
            <input
              className='form-control'
              type='password'
              placeholder='Password*'
              name='password'
              onChange={onChange}
              value={password}
              required
            />
          </div>
          <div className='form-group'>
            <button className='btn btn-primary mt-3' type='submit'>
              Login
            </button>
          </div>
        </form>
        <Link className='forgotten-password-text' to='/forgotten-password'>
          Forgotten your password?
        </Link>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { login, loadUser })(Login);
