// Import action types
import {
  DELETE_USER_SUCCESS,
  DELETE_USER_FAIL,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAIL,
  GET_PROFILES_SUCCESS,
  GET_PROFILES_FAILURE,
  CREATE_USER_SUCCESS,
  CREATE_USER_FAIL,
} from "../actions/types";

// Initial state
const initialState = {
  profiles: [],
  users: [], // Array to hold user data
  error: null, // Variable to hold error messages
  successMessage: null,
  errorMessage: null,
};

// Reducer function
export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case DELETE_USER_SUCCESS:
      // Filter out the deleted user from the state
      return {
        ...state,
        users: state.users.filter((user) => user.id !== payload),
        error: null, // Reset error state
      };
    case DELETE_USER_FAIL:
      return {
        ...state,
        error: payload, // Set error message
      };
    case CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        successMessage: payload,
        errorMessage: null,
      };
    case CHANGE_PASSWORD_FAIL:
      return {
        ...state,
        successMessage: null,
        errorMessage: payload,
      };
    case CREATE_USER_SUCCESS:
      return {
        ...state,
        successMessage: payload,
        errorMessage: null,
      };
    case CREATE_USER_FAIL:
      return {
        ...state,
        successMessage: null,
        errorMessage: payload,
      };
    case GET_PROFILES_SUCCESS:
      return {
        ...state,
        profiles: action.payload,
        error: null,
      };
    case GET_PROFILES_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
}
