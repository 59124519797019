import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Layout from "./hocs/Layout";
import Home from "./containers/Home";
import Dashboard from "./containers/Dashboard";
import PasswordReset from "./containers/ResetPassword";
import Login from "./containers/Login";
import PasswordResetConfirm from "./containers/PasswordResetConfirm";
import PrivateRoute from "./hocs/PrivateRoute";
import { useDispatch, useSelector } from "react-redux";
import { checkAuth, loadUser } from "./actions/auth";
import "react-toastify/dist/ReactToastify.css";

const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    // Check authentication state on application startup
    dispatch(checkAuth());
    dispatch(loadUser());
  }, [dispatch]);

  const user = useSelector((state) => state.auth.user);

  return (
    <Router>
      <Layout>
        <Routes>
          <Route path='/' element={<Home />} />
          {/* <Route path='/register' element={<Register />} /> */}
          <Route path='/login' element={<Login />} />
          <Route path='/forgotten-password' element={<PasswordReset />} />
          <Route
            path='/password-reset/confirm'
            element={<PasswordResetConfirm />}
          />
          <Route
            path='/dashboard/*'
            element={
              <PrivateRoute>
                <Dashboard user={user} />
              </PrivateRoute>
            }
          />
        </Routes>
      </Layout>
    </Router>
  );
};

export default App;
