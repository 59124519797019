import React, { useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  moveNextStep,
  expandInput,
  getSearchWords,
  setPrompt,
} from "../../actions/article";
import LoadingIndicator from "./LoadingIndicator";

const Input = ({ user }) => {
  const dispatch = useDispatch();
  const prompt = useSelector((state) => state.article.formData.prompt); // Get prompt from Redux state
  const promptRef = useRef();
  const [loading, setLoading] = useState(false); // State to track loading state
  const orgId = user ? user.orgId : null;

  const handleNextStep = async () => {
    setLoading(true); // Set loading state to true before API call

    const currentPrompt = promptRef.current.value;

    try {
      const expandedData = await dispatch(expandInput(currentPrompt, orgId));
      const summaries = expandedData.result;

      dispatch(getSearchWords(currentPrompt, summaries));
      dispatch(moveNextStep({ prompt: currentPrompt }));
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false); // Set loading state to false after API call completes
    }
  };

  const handleInputChange = () => {
    dispatch(setPrompt(promptRef.current.value)); // Update the prompt in Redux state when input changes
  };

  return (
    <div className='step-content dashboard-input-wrapper step-1'>
      {loading ? ( // Display loader when loading state is true
        <div className='dotloader-wrapper'>
          <LoadingIndicator text='Generating search words' />
        </div>
      ) : (
        <>
          <div className='dashboard-input-container'>
            <h4 className='instructions'>
              Describe what you want your article to be about, include at least
              one URL
            </h4>
            <hr />
            <textarea
              ref={promptRef}
              className='form-control'
              placeholder='Enter your instructions'
              // onChange={handleInputChange}
            />
          </div>

          <div className='step-nav'>
            <button type='button' onClick={handleNextStep} disabled={loading}>
              {" "}
              {/* Disable button while loading */}
              Next Step
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default Input;
