import React, { useState, useEffect } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { updateProfile, fetchProfiles } from "../actions/user";
import { authClearMessages } from "../actions/auth";
import ChangePassword from "./ChangePassword";
import { ToastContainer, toast } from "react-toastify";
import { deleteUser } from "../actions/user";
import { logout } from "../actions/auth";

const Settings = ({
  deleteUser,
  updateProfile,
  user_id,
  first_name,
  last_name,
  email,
  preferred_language,
  profiles,
  fetchProfiles,
}) => {
  const [formData, setFormData] = useState({
    first_name: first_name || "",
    last_name: last_name || "",
    email: email || "",
    preferred_language: preferred_language || "",
  });

  const success = useSelector((state) => state.auth.success);
  const error = useSelector((state) => state.auth.error);
  const dispatch = useDispatch();

  useEffect(() => {
    fetchProfiles(); // Fetch profiles when component mounts
  }, [fetchProfiles]);

  useEffect(() => {
    if (success || error) {
      toast[success ? "success" : "error"](success || error, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      dispatch(authClearMessages());
    }
  }, [success, error, dispatch]);

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();
    const { first_name, last_name, email, preferred_language } = formData;
    updateProfile(user_id, first_name, last_name, email, preferred_language);
  };

  const handleDeleteAccount = async () => {
    if (window.confirm("Are you sure you want to delete your account?")) {
      try {
        // Call deleteUser action
        await deleteUser(user_id);

        // Call logout action after deleteUser action is completed
        dispatch(logout());

        // You can also redirect the user to a different page after logging out
        // history.push('/login'); // Assuming you are using React Router and have access to history object
      } catch (error) {
        // Handle errors if any
        console.error("Error deleting user:", error);
      }
    }
  };

  return (
    <div className='dashboard-input-wrapper'>
      <ToastContainer />
      <div className='dashboard-input-container'>
        {first_name ? (
          <h1 className='mt-3'>Welcome {first_name}!</h1>
        ) : (
          <h1 className='mt-3'>Welcome!</h1>
        )}
        <p className='mt-3 mb-3'>Update your profile</p>
      </div>
      <div className='dashboard-input-container'>
        <form onSubmit={(e) => onSubmit(e)}>
          <div className='form-group__wrapper'>
            <label htmlFor='first_name' className='form-label'>
              First Name
            </label>
            <input
              type='text'
              className='form-control'
              name='first_name'
              placeholder={first_name}
              onChange={(e) => onChange(e)}
              value={formData.first_name}
            />
          </div>
          <div className='form-group__wrapper mt-4'>
            <label htmlFor='last_name' className='form-label'>
              Last Name
            </label>
            <input
              type='text'
              className='form-control'
              name='last_name'
              placeholder={last_name}
              onChange={(e) => onChange(e)}
              value={formData.last_name}
            />
          </div>
          <div className='form-group__wrapper mt-4'>
            <label htmlFor='email' className='form-label'>
              Email
            </label>
            <input
              type='email'
              className='form-control'
              name='email'
              placeholder={email}
              onChange={(e) => onChange(e)}
              value={formData.email}
            />
          </div>
          <div className='form-group__wrapper mt-4'>
            <label htmlFor='preferred_language' className='form-label'>
              Default Language
            </label>
            <select
              id='preferred_language'
              className='form-control'
              name='preferred_language'
              value={formData.preferred_language}
              onChange={(e) => onChange(e)}
            >
              <option value='EN_GB'>English (British)</option>
              <option value='EN'>English</option>
              <option value='NL'>Nederlands</option>
            </select>
          </div>
          <div className='form-group__wrapper mt-4'>
            <label htmlFor='profile' className='form-label'>
              Select Profile
            </label>
            <select
              id='profile'
              className='form-control'
              name='profile'
              value={formData.profile}
              onChange={(e) => onChange(e)}
            >
              <option value=''>Select Profile</option>
              {profiles &&
                profiles.length > 0 &&
                profiles.map((profile) => (
                  <option key={profile.id} value={profile.id}>
                    {profile.name}
                  </option>
                ))}
            </select>
          </div>
          <div className='form-group__wrapper'>
            <button className='btn btn-primary mt-4'>Update Profile</button>
          </div>
        </form>
      </div>
      <div className='dashboard-input-container'>
        <ChangePassword />
      </div>
      <div className='dashboard-input-container'>
        <div className='form-group__wrapper'>
          <button
            type='button'
            href='#!'
            className='btn btn-danger'
            onClick={() => handleDeleteAccount()}
          >
            Delete Account
          </button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user_id: state.auth.user ? state.auth.user.id : null,
  first_name: state.auth.user ? state.auth.user.first_name : null,
  last_name: state.auth.user ? state.auth.user.last_name : null,
  email: state.auth.user ? state.auth.user.email : null,
  preferred_language: state.auth.user
    ? state.auth.user.preferred_language
    : null,
  profiles: state.user.profiles,
});

export default connect(mapStateToProps, {
  deleteUser,
  updateProfile,
  fetchProfiles,
})(Settings);
