import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  moveNextStep,
  movePreviousStep,
  getSearchResults,
} from "../../actions/article";
import LoadingIndicator from "./LoadingIndicator";

const SearchWords = ({ user }) => {
  const dispatch = useDispatch();

  // Use useSelector to access the state from your Redux store
  const formData = useSelector((state) => state.article.formData);
  const { search_terms, all_sources } = formData;
  let textInputRef = useRef();
  const orgId = user ? user.orgId : null;

  useEffect(() => {
    if (textInputRef.current && search_terms) {
      textInputRef.current.value = search_terms;
    }
  }, [search_terms]);

  const handleNextStep = async () => {
    try {
      // Call API to expand input and fetch search terms
      const all_sources = await dispatch(getSearchResults(search_terms, orgId));

      // Dispatch action to update Redux state with fetched data
      dispatch(
        moveNextStep({ search_terms: textInputRef.current.value, all_sources })
      );
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <div className='step-content dashboard-input-wrapper step-2'>
      <div className='dashboard-input-container'>
        <h4 className='instructions'>
          These are your search words, edit them to get the results you want.
        </h4>
        <hr />
        {all_sources ? (
          <textarea
            className='form-control'
            ref={textInputRef}
            defaultValue={search_terms}
          />
        ) : (
          <div className='dotloader-wrapper'>
            <LoadingIndicator text='Getting search results' />
          </div>
        )}
      </div>

      <div className='step-nav'>
        <button onClick={() => dispatch(movePreviousStep())}>Back</button>
        <button type='button' onClick={handleNextStep}>
          Next Step
        </button>
      </div>
    </div>
  );
};

export default SearchWords;
