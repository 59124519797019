import {
  GET_ORGANIZATION_FAIL,
  GET_ORGANIZATION_SUCCESS,
  CREATE_ORGANIZATION_FAIL,
  CREATE_ORGANIZATION_SUCCESS,
  GET_ORGANIZATION_USERS_FAIL,
  GET_ORGANIZATION_USERS_SUCCESS,
  ADD_USER_TO_ORGANIZATION_FAIL,
  ADD_USER_TO_ORGANIZATION_SUCCESS,
  ADD_USER_TO_ORGANIZATION_SUPER_FAIL,
  ADD_USER_TO_ORGANIZATION_SUPER_SUCCESS,
  DELETE_ORGANIZATION_USER_FAIL,
  DELETE_ORGANIZATION_USER_SUCCESS,
  CLEAR_MESSAGES,
  FETCH_ORGANIZATIONS_SUCCESS,
  FETCH_ORGANIZATIONS_FAIL,
  FETCH_ORGANIZATION_SUCCESS,
  FETCH_ORGANIZATION_FAIL,
  DELETE_ORGANIZATION_SUCCESS,
  DELETE_ORGANIZATION_FAIL,
  FETCH_PROFILES_SUCCESS,
  FETCH_PROFILES_FAIL,
  FETCH_SAVE_PROFILES_SUCCESS,
  FETCH_SAVE_PROFILES_FAIL,
  UPDATE_ORGANIZATION_PROFILES_SUCCESS,
  UPDATE_ORGANIZATION_PROFILES_FAIL,
} from "../actions/types";

const initialState = {
  organizations: [],
  users: [],
  profiles: [],
  error: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case DELETE_ORGANIZATION_SUCCESS:
      return {
        ...state,
        error: null,
      };
    case DELETE_ORGANIZATION_FAIL:
      return {
        ...state,
        error: payload,
      };
    case FETCH_ORGANIZATIONS_SUCCESS:
      return {
        ...state,
        organizations: payload,
      };
    case FETCH_ORGANIZATIONS_FAIL:
      return {
        ...state,
        organizations: [],
        error: payload,
      };
    case FETCH_ORGANIZATION_SUCCESS:
      return {
        ...state,
        organizations: payload,
      };
    case FETCH_ORGANIZATION_FAIL:
      return {
        ...state,
        organizations: [],
        error: payload,
      };
    case GET_ORGANIZATION_USERS_SUCCESS:
      return {
        ...state,
        data: action.payload,
      };
    case GET_ORGANIZATION_SUCCESS:
      return {
        ...state,
        data: payload,
      };
    case CREATE_ORGANIZATION_SUCCESS:
      return {
        ...state,
        data: [...state.data, payload],
      };
    case CREATE_ORGANIZATION_FAIL:
    case GET_ORGANIZATION_FAIL:
      return {
        ...state,
        organizations: [],
      };
    case ADD_USER_TO_ORGANIZATION_SUCCESS:
      return {
        ...state,
        users: [...state.users, action.payload],
        success: action.payload,
      };
    case ADD_USER_TO_ORGANIZATION_SUPER_SUCCESS:
      return {
        ...state,
        users: [...state.users, action.payload],
        success: action.payload,
      };
    case ADD_USER_TO_ORGANIZATION_FAIL:
    case ADD_USER_TO_ORGANIZATION_SUPER_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case GET_ORGANIZATION_USERS_FAIL:
      return {
        ...state,
        users: [],
      };
    case DELETE_ORGANIZATION_USER_SUCCESS:
      return {
        ...state,
        users: state.data.users.filter((user) => user.id !== payload),
      };
    case CLEAR_MESSAGES:
      return {
        ...state,
        success: null,
        error: null,
        message: null,
      };
    case DELETE_ORGANIZATION_USER_FAIL:
      return {
        ...state,
      };
    case FETCH_PROFILES_SUCCESS:
      return {
        ...state,
        profiles: action.payload,
        error: null,
      };
    case FETCH_PROFILES_FAIL:
      return {
        ...state,
        profiles: [],
        error: action.payload,
      };
    case FETCH_SAVE_PROFILES_SUCCESS:
      return {
        ...state,
        profiles: action.payload,
        error: null,
      };
    case FETCH_SAVE_PROFILES_FAIL:
      return {
        ...state,
        profiles: [],
        error: action.payload,
      };
    case UPDATE_ORGANIZATION_PROFILES_FAIL:
      return {
        ...state,
        error: payload,
      };
    case UPDATE_ORGANIZATION_PROFILES_SUCCESS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
}
