import React, { Fragment } from "react";
import Navbar from "../components/Navbar";
import { useLocation } from "react-router-dom";

const Layout = ({ children }) => {
  const location = useLocation();
  const isDashboardRoute = location.pathname.startsWith("/dashboard");

  return (
    <Fragment>
      {!isDashboardRoute && <Navbar />} {/* Render Navbar conditionally */}
      {children}
    </Fragment>
  );
};

export default Layout;
