import React from "react";
import { Link } from "react-router-dom";

const Home = () => (
  <div className='container home-container'>
    <div className='wrapper-container'>
      <h1 className='display-4'>Welcome to Laio</h1>
      <p className='lead'>This is a wonderfull app with stuff and stuff</p>
      <hr className='my-4' />
      <Link className='btn btn-primary btn-lg' to='/login'>
        Login
      </Link>
    </div>
  </div>
);

export default Home;
