import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import Input from "./article-creation/Input";
import SearchWords from "./article-creation/SearchWords";
import SearchResults from "./article-creation/SearchResults";
import Review from "./article-creation/Review";
import Outline from "./article-creation/Outline";
import Article from "./article-creation/Article";

const MultiStepForm = ({ user }) => {
  const currentStep = useSelector((state) => state.article.currentStep);
  const formData = useSelector((state) => state.article.formData);

  return (
    <>
      {currentStep === 1 && <Input user={user} />}
      {currentStep === 2 && <SearchWords input={formData.input} user={user} />}
      {currentStep === 3 && (
        <SearchResults
          user={user}
          inputText={formData.input}
          responseText={formData.responseText}
        />
      )}
      {currentStep === 4 && (
        <Review
          user={user}
          selectedSources={formData.selectedSources}
          responseText={formData.responseText}
        />
      )}
      {currentStep === 5 && (
        <Outline
          user={user}
          selectedSources={formData.selectedSources}
          responseText={formData.responseText}
          title={formData.title}
          intro={formData.intro}
        />
      )}
      {currentStep === 6 && (
        <Article user={user} finalHTMLResponse={formData.finalHTMLResponse} />
      )}
    </>
  );
};

export default MultiStepForm;
