import axios from "axios";
import {
  CLEAR_MESSAGES,
  UPDATE_USER_PROFILE_FAIL,
  UPDATE_USER_PROFILE_SUCCESS,
  CREATE_USER_SUCCESS,
  CREATE_USER_FAIL,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAIL,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAIL,
  USER_ROLE_UPDATED,
  GET_PROFILES_SUCCESS,
  GET_PROFILES_FAILURE,
} from "./types";

export const updateProfile =
  (userId, first_name, last_name, email, preferred_language) =>
  async (dispatch) => {
    const config = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    const body = JSON.stringify({
      first_name,
      last_name,
      email, // Use email instead of username
      preferred_language,
    });

    try {
      const res = await axios.put(
        `${process.env.REACT_APP_API_URL}api/users/${userId}`,
        body,
        config
      );

      if (res.data.profile && res.data.email) {
        // Check for email instead of username
        dispatch({
          type: UPDATE_USER_PROFILE_SUCCESS,
          payload: res.data,
        });
      } else {
        dispatch({
          type: UPDATE_USER_PROFILE_FAIL,
        });
      }
    } catch (err) {
      dispatch({
        type: UPDATE_USER_PROFILE_FAIL,
      });
    }
  };

export const ProfileClearMessages = () => ({
  type: CLEAR_MESSAGES,
});

export const createUser = (email, organizationId) => async (dispatch) => {
  // Remove username parameter
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({ email, organizationId }); // Use email instead of username

  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}api/users/`,
      body,
      config
    );

    dispatch({
      type: CREATE_USER_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: CREATE_USER_FAIL,
      payload: err.response.data.message || "Failed to create user",
    });
  }
};

export const deleteUser = (userId) => async (dispatch) => {
  try {
    await axios.delete(`${process.env.REACT_APP_API_URL}api/users/${userId}`);

    dispatch({
      type: DELETE_USER_SUCCESS,
      payload: userId,
    });
  } catch (err) {
    dispatch({
      type: DELETE_USER_FAIL,
      payload: err.response.data.message || "Failed to delete user",
    });
  }
};

export const changePassword =
  (id, oldPassword, newPassword) => async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const body = JSON.stringify({ oldPassword, newPassword });

    try {
      const res = await axios.put(
        `${process.env.REACT_APP_API_URL}api/users/${id}/change-password`,
        body,
        config
      );

      dispatch({
        type: CHANGE_PASSWORD_SUCCESS,
        payload: res.data.message,
      });
    } catch (err) {
      let errorMessage = "An unknown error occurred.";
      if (err.response && err.response.data && err.response.data.message) {
        errorMessage = err.response.data.message;
      }
      dispatch({
        type: CHANGE_PASSWORD_FAIL,
        payload: errorMessage,
      });
    }
  };

export const updateUserRole = (userId, isAdmin) => async (dispatch) => {
  try {
    await axios.put(
      `${process.env.REACT_APP_API_URL}api/users/${userId}/change-role`,
      {
        isAdmin,
      }
    );
    dispatch({ type: USER_ROLE_UPDATED, payload: { userId, isAdmin } });
  } catch (error) {
    console.error("Error updating user role:", error);
    // Handle error
  }
};

export const fetchProfiles = () => async (dispatch) => {
  try {
    // Make API request to fetch profiles
    const res = await axios.get(`${process.env.REACT_APP_API_URL}api/profiles`); // Replace '/api/profiles' with your actual backend API endpoint

    // Dispatch action with profiles data if request succeeds
    dispatch({
      type: GET_PROFILES_SUCCESS,
      payload: res.data.profiles, // Assuming the response contains a 'profiles' array
    });
  } catch (err) {
    // Dispatch action with error message if request fails
    dispatch({
      type: GET_PROFILES_FAILURE,
      payload: err.response.data.message, // Assuming the error response contains a 'message' field
    });
  }
};
