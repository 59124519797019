import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { authClearMessages } from "../actions/auth";
import { ToastContainer, toast } from "react-toastify";

const PasswordReset = () => {
  const dispatch = useDispatch();
  const message = useSelector((state) => state.auth.message);
  const error = useSelector((state) => state.auth.error);
  const [showForm, setShowForm] = useState(false);
  const [email, setEmail] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    // dispatch(reset_password(email));
  };

  useEffect(() => {
    if (message) {
      toast.success(message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setShowForm(true);
      dispatch(authClearMessages());
    }
    if (error) {
      toast.error(error, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      dispatch(authClearMessages());
    }
  }, [message, error, dispatch]);

  return (
    <div className='container auth-page-container'>
      <ToastContainer />
      <div className='wrapper-container'>
        <div className='laio-welcome'>
          <h2 className='laio-welcome-title'>Forgot your password?</h2>
        </div>
        <form onSubmit={handleSubmit} className='form-group'>
          <label className='form-label mt-3' htmlFor='email'>
            Email
          </label>
          <input
            type='email'
            id='email'
            className='form-control'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <button type='submit' className='btn btn-primary mt-2'>
            Reset Password
          </button>
          {showForm && (
            <div className='form-group'>
              <p className=''>
                Check your email for a link to reset your password.
              </p>
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default PasswordReset;
