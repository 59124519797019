import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { DotLoader } from "react-spinners";
import {
  writeArticle,
  moveNextStep,
  movePreviousStep,
} from "../../actions/article";
const Article = () => {
  const dispatch = useDispatch();
  const formData = useSelector((state) => state.article.formData);
  // Now you can access the data in formData
  const { title, intro, body } = formData;

  return (
    <div className='step-content dashboard-input-wrapper step-6'>
      <div className='dashboard-input-container finished-article-body'>
        <h1>{title}</h1>
        <div
          className='article-intro'
          dangerouslySetInnerHTML={{ __html: intro }}
        />
        {body.map((paragraph, index) => (
          <div key={index} className='article-paragraph'>
            <h2>{paragraph.paragraphtitle}</h2>
            <p dangerouslySetInnerHTML={{ __html: paragraph.paragraphtext }} />
          </div>
        ))}
        <div className='article-sources'>
          <h2>Sources:</h2>
          <ul>
            {Object.keys(body[body.length - 1]).map(
              (sourceKey, index) =>
                sourceKey.startsWith("source") && (
                  <li key={index}>
                    <a
                      href={body[body.length - 1][sourceKey]}
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      {body[body.length - 1][sourceKey]}
                    </a>
                  </li>
                )
            )}
          </ul>
        </div>
      </div>
      <div className='step-nav'>
        <button onClick={() => dispatch(movePreviousStep())}>Back</button>
      </div>
    </div>
  );
};

export default Article;
