import React from "react";
import { connect } from "react-redux";

import { Navigate, useLocation } from "react-router-dom";

const PrivateRoute = ({ children, isAuthenticated }) => {
  const location = useLocation();

  return isAuthenticated ? (
    children
  ) : (
    <Navigate to='/login' state={{ from: location }} />
  );
};
const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, {})(PrivateRoute);
