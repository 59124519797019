import React, { useState, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { authClearMessages } from "../actions/auth";
import { resetPassword } from "../actions/auth";
import { ToastContainer, toast } from "react-toastify";

const PasswordResetConfirm = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token");

  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const dispatch = useDispatch();
  const message = useSelector((state) => state.auth.message);
  const error = useSelector((state) => state.auth.error);
  const [showForm, setShowForm] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!token) {
      // Handle case where token is undefined
      console.error("Token is undefined");
      return;
    }
    if (newPassword !== confirmPassword) {
      toast.warn("Your passwords don't match", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }
    dispatch(resetPassword(token, newPassword));
  };

  useEffect(() => {
    if (message) {
      toast.success(message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setShowForm(true);
      dispatch(authClearMessages());
    }
    if (error) {
      toast.error(error, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      dispatch(authClearMessages());
    }
  }, [message, error, dispatch]);

  return (
    <div className='container auth-page-container'>
      <ToastContainer />
      <div className='wrapper-container'>
        <div className='laio-welcome'>
          <h2 className='laio-welcome-title'>Welcome to Laio!</h2>
          <p className='laio-welcome-text'>
            Set the password of your Laio account here:
          </p>
        </div>
        <form onSubmit={handleSubmit} className='form-group'>
          <label className='form-label mt-3' htmlFor='newPassword'>
            New Password
          </label>
          <input
            type='password'
            id='newPassword'
            className='form-control'
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            required
          />
          <label className='form-label mt-3' htmlFor='confirmPassword'>
            Confirm Password
          </label>
          <input
            type='password'
            id='confirmPassword'
            className='form-control'
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
          <button type='submit' className='btn btn-primary mt-2'>
            Set Password
          </button>
          {showForm && (
            <div className='form-group reset-login'>
              <p className=''>
                You can now:
                <Link className='btn btn-primary btn-lg' to='/login'>
                  Login
                </Link>
              </p>
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default PasswordResetConfirm;
