// middleware/tokenExpiration.js

import { LOGOUT } from "../actions/types";

const tokenExpirationMiddleware =
  ({ getState, dispatch }) =>
  (next) =>
  (action) => {
    const tokenExpiration = localStorage.getItem("tokenExpiration");
    if (tokenExpiration && Date.now() >= tokenExpiration) {
      dispatch({ type: LOGOUT });
    }
    return next(action);
  };

export default tokenExpirationMiddleware;
