// actions/article.js
import {
  SET_PROMPT,
  EXPAND_INPUT_SUCCESS,
  EXPAND_INPUT_FAIL,
  GENERATE_SUMMARY_SUCCESS,
  GENERATE_SUMMARY_FAIL,
  GET_SEARCH_WORDS_SUCCESS,
  GET_SEARCH_WORDS_FAIL,
  SAVE_MORE_INFO_ANSWERS,
  MOVE_NEXT_STEP,
  MOVE_PREVIOUS_STEP,
  SET_SEARCH_TERMS,
  GET_SEARCH_RESULTS_SUCCESS,
  GET_SEARCH_RESULTS_FAIL,
  SAVE_SELECTED_SOURCES,
  GET_MORE_INFO_FAIL,
  GET_MORE_INFO_SUCCESS,
  WRITE_TITLE_INTRO_FAIL,
  WRITE_TITLE_INTRO_SUCCESS,
  WRITE_ARTICLE_FAIL,
  WRITE_ARTICLE_SUCCESS,
  SAVE_SUMMARIES,
  UPDATE_INTRO,
  UPDATE_TITLE,
} from "./types"; // Import your action types
import axios from "axios";
import Cookies from "js-cookie";

export const setPrompt = (prompt) => ({
  type: SET_PROMPT,
  payload: prompt,
});

export const setSearchTerms = (search_terms) => ({
  type: SET_SEARCH_TERMS,
  payload: search_terms,
});

// export const toggleSourceSelection = (sourceId) => ({
//   type: TOGGLE_SOURCE_SELECTION,
//   payload: sourceId,
// });

export const saveSelectedSources = (sources) => ({
  type: SAVE_SELECTED_SOURCES,
  payload: sources,
});

export const saveSummaries = (summaries) => ({
  type: SAVE_SUMMARIES,
  payload: summaries,
});
export const saveMoreInfoAnswers = (more_info_answers) => ({
  type: SAVE_MORE_INFO_ANSWERS,
  payload: more_info_answers,
});
export const updateTitle = (title) => ({
  type: UPDATE_TITLE,
  payload: title,
});
export const updateIntro = (intro) => ({
  type: UPDATE_INTRO,
  payload: intro,
});

export const moveNextStep = (data) => ({
  type: MOVE_NEXT_STEP,
  payload: data,
});
export const movePreviousStep = (data) => ({
  type: MOVE_PREVIOUS_STEP,
  payload: data,
});

export const expandInput = (input_query, orgId) => async (dispatch) => {
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-Organization-ID": orgId,
    },
  };
  const body = JSON.stringify({
    function: "expandInput",
    prompt: input_query,
    version: "1.0",
    profile: "professional",
    language: "en_UK",
  });

  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}api/laio/`,
      body,
      config
    );

    // Dispatch a success action if needed
    dispatch({
      type: EXPAND_INPUT_SUCCESS,
      payload: res.data, // Use 'res.data' instead of 'response.data'
    });

    // You can return data if necessary
    return res.data;
  } catch (error) {
    // Dispatch a failure action if needed
    dispatch({
      type: EXPAND_INPUT_FAIL,
      payload: error,
    });

    throw error;
  }
};

export const generateSummary = (prompt, url, orgId) => async (dispatch) => {
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-Organization-ID": orgId,
    },
  };
  const body = JSON.stringify({
    function: "generateSummary",
    url: url,
    prompt: prompt,
    snippet: "What is all the fuss about nitrogen in the Netherlands?",
    date: "2022-06-20",
    source: "web",
    version: "1.0",
    profile: "professional",
    language: "en_UK",
  });

  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}api/laio/`,
      body,
      config
    );

    // Dispatch a success action if needed
    dispatch({
      type: GENERATE_SUMMARY_SUCCESS,
      payload: res.data, // Use 'res.data' instead of 'response.data'
    });

    // You can return data if necessary
    return res.data;
  } catch (error) {
    // Dispatch a failure action if needed
    dispatch({
      type: GENERATE_SUMMARY_FAIL,
      payload: error,
    });

    throw error;
  }
};

export const getSearchWords =
  (input_query, summaries, orgId) => async (dispatch) => {
    const config = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-Organization-ID": orgId,
      },
    };
    const body = JSON.stringify({
      function: "getSearchWords",
      prompt: input_query,
      version: "1.0",
      profile: "professional",
      language: "en_UK",
      summaries: summaries,
    });

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}api/laio/`,
        body,
        config
      );

      // Dispatch a success action if needed
      dispatch({
        type: GET_SEARCH_WORDS_SUCCESS,
        payload: res.data, // Use 'res.data' instead of 'response.data'
      });

      // You can return data if necessary
      return res.data;
    } catch (error) {
      // Dispatch a failure action if needed
      dispatch({
        type: GET_SEARCH_WORDS_FAIL,
        payload: error,
      });

      throw error;
    }
  };

export const getSearchResults = (search_terms, orgId) => async (dispatch) => {
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-Organization-ID": orgId,
    },
  };
  const body = JSON.stringify({
    function: "searchWeb",
    instructions: search_terms,
    version: "1.0",
    profile: "professional",
    language: "en_UK",
  });

  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}api/laio/`,
      body,
      config
    );

    // Dispatch a success action if needed
    dispatch({
      type: GET_SEARCH_RESULTS_SUCCESS,
      payload: res.data, // Use 'res.data' instead of 'response.data'
    });

    // You can return data if necessary
    return res.data;
  } catch (error) {
    // Dispatch a failure action if needed
    dispatch({
      type: GET_SEARCH_RESULTS_FAIL,
      payload: error,
    });

    throw error;
  }
};

export const getMoreInfo =
  (input_query, summaries, orgId) => async (dispatch) => {
    const config = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-Organization-ID": orgId,
      },
    };
    const body = JSON.stringify({
      function: "getMoreInfo",
      prompt: input_query,
      version: "1.0",
      profile: "professional",
      language: "en_UK",
      summaries: summaries,
    });

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}api/laio/`,
        body,
        config
      );

      // Dispatch a success action if needed
      dispatch({
        type: GET_MORE_INFO_SUCCESS,
        payload: res.data, // Use 'res.data' instead of 'response.data'
      });

      // You can return data if necessary
      return res.data;
    } catch (error) {
      // Dispatch a failure action if needed
      dispatch({
        type: GET_MORE_INFO_FAIL,
        payload: error,
      });

      throw error;
    }
  };

export const writeTitleIntro =
  (prompt, more_info_answers, more_info_questions, summaries, orgId) =>
  async (dispatch) => {
    const config = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-Organization-ID": orgId,
      },
    };

    const body = JSON.stringify({
      function: "writeTitleIntro",
      prompt: prompt,
      version: "1.0",
      profile: "professional",
      language: "en_UK",
      more_info_questions: more_info_questions,
      more_info_answers: more_info_answers,
      summaries: summaries,
    });

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}api/laio/`,
        body,
        config
      );

      // Dispatch a success action if needed
      dispatch({
        type: WRITE_TITLE_INTRO_SUCCESS,
        payload: res.data, // Use 'res.data' instead of 'response.data'
      });

      // You can return data if necessary
      return res.data;
    } catch (error) {
      // Dispatch a failure action if needed
      dispatch({
        type: WRITE_TITLE_INTRO_FAIL,
        payload: error,
      });

      throw error;
    }
  };

export const writeArticle =
  (
    prompt,
    more_info_questions,
    more_info_answers,
    title,
    intro,
    summaries,
    orgId
  ) =>
  async (dispatch) => {
    const config = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-Organization-ID": orgId,
      },
    };

    const body = JSON.stringify({
      function: "writeArticle",
      prompt: prompt,
      version: "1.0",
      profile: "professional",
      language: "en_UK",
      more_info_questions: more_info_questions,
      more_info_answers: more_info_answers,
      title: title,
      intro: intro,
      summaries: summaries,
    });

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}api/laio/`,
        body,
        config
      );

      // Dispatch a success action if needed
      dispatch({
        type: WRITE_ARTICLE_SUCCESS,
        payload: res.data, // Use 'res.data' instead of 'response.data'
      });

      // You can return data if necessary
      return res.data;
    } catch (error) {
      // Dispatch a failure action if needed
      dispatch({
        type: WRITE_ARTICLE_FAIL,
        payload: error,
      });

      throw error;
    }
  };
